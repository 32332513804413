

.Login {
  background-color: var(--background);
  background-image:var(--background-img);
  
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  width:100%;
  height: 100%;
  text-align: center;
  color: #fff;
  position: absolute;
  z-index: 100;
  padding: 0px 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -51%);
}
.Login .title {
  color: white;
  text-align: center;
  font-size: 30px;
}
.Login .inputWrapper {
  font-size: 15px;
}
.Login .inputWrapper input {
  outline: none;
  width: 100%;
  height: 30px;
  font-size: 15px;
  padding-left: 5px;
  color: #FFFFFF;
}
.Login .inputWrapper select {
  outline: none;
  width: 100%;
  height: 30px;
  font-size: 15px;
  padding-left: 5px;
  color: black;
  cursor: pointer;
}
.Login .label {
  display: block;
  text-align: left;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Login .error {
  color: red;
  display: block;
  margin: 10px auto;
  font-size: 15px;
}
.btn {
  margin: 20px auto;
  display: block;
  outline: none;
  /* border-radius: 25px; */
  height: 50px;
  width: 100%;
  font-size: 15px;
  color: #fff;
  /* text-transform: uppercase; */
  font-weight: bold;
  border: solid 2px #fff;
}

.btn:hover {
  background: #c5c5c5;
}

.Login table {
  width: 100%;
  text-align: left;
  font-size: 13px;
}

.Login table tr {
  height: 40px;
}

.changeServerBox {
  position: relative;
}

.changeServerBox > input {
  padding-right: 80px;
}

.changeServerBox2 {
  position: relative;
}

.changeServerBox2 > div {
  width:100%;
  padding: 8px 80px 8px 0;
  color: #fff !important;
  background: none;
  font-size: 13px;
  font-weight: 700;
  height: 100%;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
}

.changeServerBox2 > button {
  color: rgb(176, 220, 250) !important;
  background: none !important;
  position: absolute;
  right: 0%;
  bottom: 0%;
  border: none;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  padding: 8px 15px;
}

.changeServerBox2 > button:hover {
  text-decoration: underline;
}

.btnChangeServer {
  position: absolute;
  right: 0%;
  bottom: 0%;
  border: none;
  background: #a6abe7;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  padding: 8px 15px;
}

.btnChangeServer:hover {
  background: #673ab7;
  box-shadow: inset 0 0 20px rgb(121, 118, 118);
}
