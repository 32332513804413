@tailwind base;
@tailwind components;
@tailwind utilities;
@import-normalize;

:root {
  --background: #673ab7;
  --iframe-header: #673ab7;
  --customConfirmBox: #9972db;
  --background-img: url('./assets/images/background.png')
}

html,
body {
  font-size: 62.5%;
  font-family: 'Open Sans', sans-serif;
  overflow: hidden;
}

.break {
  flex-basis: 100%;
  height: 0;
}
.box {
  height: 51rem;
}
/* .button_number {
    width: 8rem;
    height: 4rem;
} */

.center_x {
  transform: translateX(-50%);
}

.square {
  width: 7rem;
  height: 7rem;
}

.active {
  background-color: #9ca3af;
}

.blur-effect:not(.chooseNumber) {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.visible {
  visibility: visible;
}
.rounded-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.rounded-bottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ringing {
  -webkit-animation: NAME-YOUR-ANIMATION 2s infinite;
  -webkit-animation-iteration-count: infinite;
}

.ringing:hover {
  -webkit-animation: NAME-YOUR-ANIMATION;
}

.upDown {
  -webkit-animation: moveInBottom 4s infinite;
  -webkit-animation-iteration-count: infinite;
}

.upDown:hover {
  -webkit-animation: moveInBottom;
}

@keyframes moveInBottom {
  0% {
  }
  10% {
    transform: translateY(30px);
  }
  20% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(30px);
  }
  60% {
    transform: translateY(0);
  }
  70% {
    transform: translateY(30px);
  }
  80% {
    transform: translateY(0);
  }
  90% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes NAME-YOUR-ANIMATION {
  0% {
  }
  10% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -o-transform: rotate(5deg);
  }
  20% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
  }
  30% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -o-transform: rotate(5deg);
  }
  40% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -o-transform: rotate(5deg);
  }
  60% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
  }
  70% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -o-transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
  }
  90% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -o-transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
  }
}

.calling {
  display: block;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 2s;
}
.notsip:after {
  content: '!';
  color: red;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, 50%);
  font-size: 1rem;
}
.notsip-setting:after {
  content: '!';
  color: red;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(100%, -50%);
  font-size: 1rem;
}

.phone {
  width: 30rem;
}

.selected {
  background-color: #ffffff;
  border-radius: 0px;
  border-bottom: 2px solid #ac88db;
  border-right: 1px solid #ac88db;
}
.selected-tab {
  border-bottom: 2px solid rgba(106, 41, 228, 0.9);
  border-right: 1px solid rgba(106, 41, 228, 0.9);
}
.shadow-button {
  box-shadow: 2px 2px rgb(185, 185, 185);
}

.shadow {
  box-shadow: 5px 10px 18px #888888;
}

.infomation {
  height: 90%;
}

/* .infomation:not(:last-child) {
    border-bottom: 5px solid purple;
} */

/* thead not scroll */

/* Iframe */
.text-underline {
  text-decoration: underline !important;
}

.block-info{
  margin-top: 5px;
}
.block-info label{
  font-size: 14px;
}

.block-info>label{
  margin-bottom: 10px;
}

.info-general .info-detail div {
  flex-basis:33.3%;
  overflow: hidden;
}

.info-name-person {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-name-person i:not(.icon-level){
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 4px;
  font-size: 14px;
  cursor: pointer;
  float: right;
}
.body-iframe-ext{
  height: 93%;
  overflow: auto;
}
.body-iframe-ext::-webkit-scrollbar {
  width: 5px;
}

.body-iframe-ext::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.body-iframe-ext::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 20px;
}
.info-iframe-ext{
  width: 100%;
  border: none;
}
.nav-iframe-ext{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
  margin: 5px 0 0 5px;
}
.nav-iframe-ext .nav-iframe-ext-item{
  padding: 10px 20px;
  border-radius: 10px 10px 5px 0;
  background: #e9eaec;
  color: black;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  box-shadow: inset 0 0 5px #cfc9c9;
  cursor: pointer;
}
.nav-iframe-ext-item-active {
  background: #673ab7 !important;
  color: white !important;
  
}
.info-person{
  padding: 0 0 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
  margin-left: 15px;
  /* margin-bottom: 10px; */
  /* height: 93%; */
  overflow: auto;
}
.info-person::-webkit-scrollbar {
  width: 5px;
}

.info-person::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.info-person::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 20px;
}
.info-person .name-person{
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  display: inline-block;
  border-bottom: 1px solid #c1c1c1;
}

/* Hubspot */
.info-person2{
  padding: 0 0 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
  margin-left: 15px;
  margin-bottom: 10px;
  height: 93%;
  overflow: auto;
}
.info-person2::-webkit-scrollbar {
  width: 5px;
}

.info-person2::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.info-person2::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 20px;
}
.info-person2 .name-person{
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  display: inline-block;
  border-bottom: 1px solid #c1c1c1;
}

.info-person2 label{
  font-weight: bold;
}

.info-right-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.info-right-icon>span {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.info-right-icon>span svg {
  font-size: 13px;
}

.info-detail div
{
  padding-right:15px;
}
.info-person label{
  font-weight: bold;
}
.info-notes ul{
  /*font-size: 13px;*/
  max-height: 55px;
  overflow-y: scroll;
}
.block-info{
  margin-top: 5px;
}
.block-info label{
  font-size: 14px;
}
.info-detail
{
  display: flex;
  justify-content: flex-start;
  align-items: baseline; 
  margin-top: 10px;
}
.info-detail div{
  flex-basis: 25%;
  margin-bottom: 10px;
  overflow: hidden;
}
.info-detail p{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.info-name-person{
  margin: 0 0 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 15px;
}
.info-detail p{
  font-size: 13px;
}
.info-notes .info-notes-edit{
  text-align: right
}
.info-notes .info-notes-edit textarea{
  width: 100%;
  border: 1px solid #cbcccd;
  border-radius: 5px;
  resize: vertical;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 10px
}
.info-notes .info-notes-edit button
{
  color: white;
  background: #673ab7;
  padding: 5px 10px;
  margin-top: 5px;
}

.input-subject{
  border: 1px solid #bdbbbb !important;
  border-radius: 7px!important;
  padding: 5px!important;
  background: #edebeb!important;
  width: 93%!important;
  font-size:14px!important;
  margin-top: 5px!important;
  margin-bottom: 5px!important;
}

.textarea-subject{
  margin-bottom: 5px;
  border-radius: 7px;
  padding: 5px;
  width: 93%;
  border: 1px solid #bdbbbb;
  background: #edebeb;
  font-size:14px;
  resize: vertical;
}

#btn-send-ticket{
  border-radius: 7px;
  background: #673ab7;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  font-size: 14px;
  border:none
}

#btn-send-ticket-2{
  border-radius: 7px;
  background: #673ab7;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  font-size: 14px;
}

.btn-add-ticket{
  margin-bottom:5px; 
  font-size:15px;
  background:#fff;
  color:rgb(51, 71, 91);
  font-weight:600;
  cursor: pointer;
}
.btn-add-ticket>span{
  font-weight: 700;
  font-size: 17px;
}
.input-new-contact-integration{
  padding: 5px 10px;
}
.input-new-contact-integration {
  width: 130px !important;
}
.input-firstname-contact-integration{
  margin-right: 8px;
}

.hide-item{
  display: none
}

#IconSpinner {
  margin-right: 10px;
  animation: rotation 2s infinite linear;
}

.info-header {
  padding: 0 20px;
  vertical-align: middle;
  line-height: 35px;
  height: 7%;
  color: white;
  background:  var(--iframe-header);
  font-size: 15px;
  font-weight: 700;
  border-radius: 4px 4px 0 0;
  box-shadow: -1px 1px 8px 2px #a5a5;
  position: relative;
}

.closeIframe{
  position: absolute;
  top: 6px;
  right: 18px;
  font-size: 18px;
  float: right;
  font-weight: 700;
  line-height: 1;
  color: rgb(250, 250, 250);
  text-shadow: 0 1px 0 #fff;
  opacity: .8;
  cursor: pointer;

  transition: all ease-in-out 0.05s;
}

.closeIframe:hover {
  opacity: 0.9;
}

.info-person-null {
  padding: 10px 20px;

  width: 100%;
  /* display: flex;
  justify-content: flex-start;
  align-items: center; */
}

.infoAddObjectBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.info-person-null::-webkit-scrollbar {
  width: 5px;
}

.info-person-null::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.info-person-null::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 20px;
}

.info-person-null .name-person{
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  display: inline-block;
  border-bottom: 1px solid #c1c1c1;
}


.info-person-null .btn-add-contact{
  margin-right: 20px;
  font-size: 20px;
  font-weight: 700;
  background:#fff;
  color:rgb(51, 71, 91);
  cursor: pointer;
  margin-left: 20px;
}

.info-person-null span{
  font-size: 15px;
  font-weight: 700;
  color:rgb(51, 71, 91);
}

.info-person-null input{
  color:rgb(51, 71, 91);
  outline: none;
  margin-right: 10px;
  border: 1px solid #bdbbbb;
  border-radius: 7px;
  padding: 5px;
  background: #edebeb;
  width: 40%;
  font-size:14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

#add-email {
  width: 40%;
}

.infoDetail
{
  display: flex;
  justify-content: flex-start;
  align-items: baseline; 
  flex-direction: column;
  margin-top: 10px;
}
.infoDetail div{
  margin-bottom: 10px;
  overflow: hidden;
}
.infoDetail p, .infoDetail a{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
  padding-right: 5px;
  display: block;
}

.infoDetail-group {
  flex-basis: 95% !important;
  width: 95%;
  background-color: #e2dadac7;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.infoDetail-group>div{
  margin: 5px 0 !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.bill-list-header span {
  font-size: 13px;
  font-weight: bold;
  margin-right: 10px;
}

.bill-list {
  font-size: 13px;    
  margin: 5px;
}

.bill-list tr:first-child {    
  font-weight: bold;  
}

.bill-list tr {
  padding-bottom: 10px;
}

.bill-list td {  
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 0;
}

.bill-list td:nth-child(1) {
  padding-left: 0px;
  width: 105px;
}

.bill-list td:nth-child(2) {
  max-width: 245px;
  padding-right: 10px;
}

#hotline svg {
  display: inline;
}

#press {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
  border-radius: 50%;
  border: 2px solid white;
  position: relative;
}

#press>span {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Custom Confirm Box */
.customConfirmBox {
  width: 280px;
  padding: 10px 20px;
  background: var(--customConfirmBox);
  color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 10px 5px #ad9cca;
}

.customConfirmBox > .customConfirmBox_title {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.customConfirmBox > .customConfirmBox_message {
  font-size: 14px;
  font-weight: 600;
}

.customConfirmBox > .customConfirmBox_btnDiv {
  margin-top: 10px;
  padding: 5px;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.customConfirmBox_btnDiv > .customConfirmBox_btn1 {
  padding: 5px 20px;
  margin-right: 20px;
  border-radius: 20px;
  background: none;
  border: 2px solid #fff;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all ease-in-out 0.2s;
}

.customConfirmBox_btnDiv > .customConfirmBox_btn2 {
  padding: 5px 20px;
  border-radius: 20px;
  background: #673ab7;
  border: 2px solid #673ab7;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all ease-in-out 0.2s;
}

.customConfirmBox_btn1:hover {
  background: #673ab7;
  border: 2px solid #673ab7;
  text-shadow: 0 0 2px #fff;
  box-shadow: 0px 0px 2px 1px #a984e9;
  transform: scale(1.1);
}

.customConfirmBox_btn2:hover {
  box-shadow: 0px 0px 2px 1px #a984e9;
  text-shadow: 0 0 2px #fff;
  transform: scale(1.1);
}

div.swal2-loader {
  border-color: #673ab7 rgba(0,0,0,0) #673ab7 rgba(0,0,0,0);
  width: 3.2em;
  height: 3.2em;
}